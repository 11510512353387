import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DynamicFormControlModel, DynamicFormService} from '@ng-dynamic-forms/core';
import {UntypedFormGroup} from '@angular/forms';
import {CrudService} from '../services/crud.service';
import {routerTransition} from '../../../../router.animations';
import {FormBuilder} from '@shared/modules/form/form-builder';
import {ServerValidation} from '@shared/modules/form/validators/server-validation';
import {serverValidator} from '@shared/modules/form/validators/server-validator';
import {take} from 'rxjs';
import {UpdateOptions} from '@shared/components/crud/update/types';
import {FormService} from "@shared/services/form.service";

@Component({
    selector:      'app-crud-update',
    template:      '',
    encapsulation: ViewEncapsulation.None,
    animations:    [routerTransition()],
    providers:     [
        DynamicFormService
    ]
})

export class CrudUpdateComponent implements OnInit {

    /**
     * Model id
     */
    public id: number;
    public successMessage                       = '';
    public formModel: DynamicFormControlModel[] = [];
    public formGroup: UntypedFormGroup;

    constructor(public route: ActivatedRoute,
                public _crudService: CrudService,
                public formService: DynamicFormService,
                private appFormService: FormService = null,
    ) {
    }

    ngOnInit() {
        this.initRouteParams();
        this.initDataModel();
    }

    initRouteParams() {
        this.route.params
            .pipe(take(1))
            .subscribe(params => {
                this.id = +params['id'];
            });
    }

    initDataModel() {
        throw new Error('Implement this method to get model data and initialize form');
    }

    initForm(model: any = [], options: UpdateOptions = null, callback = null, updateFormConfigurationsMethodName: string = 'getUpdateFormConfigurations') {
        options.route = options.route || {params: {type: 'update'}};

        this._crudService[updateFormConfigurationsMethodName](options)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    const formConfigurations = data['fields'];
                    this.formModel           = (new FormBuilder(formConfigurations, model)).build();
                    this.formGroup           = this.formService.createFormGroup(this.formModel);

                    if (callback !== null) {
                        callback();
                    }
                }
            );
    }

    validate(currentControl: DynamicFormControlModel, options: UpdateOptions = null) {
        options = options || {route: {params: {id: this.id}, data: {data: this.formGroup.value}}};
        if (!('serverValidator' in currentControl['validators']) || this.formGroup.get(currentControl.name).invalid) {
            return;
        }

        this._crudService.validateOnUpdate(options)
            .pipe(take(1))
            .subscribe(
                () => {
                    if (options.callbacks) {
                        if (options.callbacks.success) {
                            options.callbacks.success();
                        }
                    }
                },
                (error) => {
                    ServerValidation.validateControl(error.errors, currentControl.name, this.formModel, this.formGroup);
                    if (options.callbacks) {
                        if (options.callbacks.error) {
                            options.callbacks.error();
                        }
                    }
                },
            );
    }


    update(options: UpdateOptions = {}, updateMethodName: string = 'update') {
        options.route              = options.route || {};
        options.route.params       = options.route.params || {};
        options.route.data         = options.route.data || {};
        options.route.params['id'] = this.id;
        options.route.data['data'] = options.route.data['data'] || this.formGroup.value;

        if (this.appFormService) {
            options.route.data = this.appFormService
                .createFormDataWithFilesIfExists(this.formGroup, options.route.data['data']);
        }

        this._crudService[updateMethodName](options)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.successMessage = data.message;
                    if (options.callbacks) {
                        if (options.callbacks.success) {
                            options.callbacks.success(data);
                        }
                    }
                },
                (errors) => {
                    let errorsResponse = JSON.parse(errors._body);
                    ServerValidation.validateControls(errorsResponse.errors, this.formModel, this.formGroup);

                    if (options.callbacks) {
                        if (options.callbacks.error) {
                            options.callbacks.error(errorsResponse);
                        }
                    }
                }
            );
    }

    isInvalidForm() {
        return this.formGroup.invalid;
    }

}
