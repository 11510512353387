import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, NG_VALIDATORS, ReactiveFormsModule} from '@angular/forms';
import {
    DISABLED_MATCHER_PROVIDER, DYNAMIC_VALIDATORS,
    DynamicFormsCoreModule,
    HIDDEN_MATCHER_PROVIDER,
    REQUIRED_MATCHER_PROVIDER, Validator, ValidatorFactory
} from '@ng-dynamic-forms/core';
import {FormComponent} from './form.component';
import {ModelNameDirective} from './directives/model-name.directive';
import {AfterModelNameDirective} from './directives/after-model-name.directive';
import {serverValidator} from './validators/server-validator';
import {DynamicFormsMaterialUIModule} from '@ng-dynamic-forms/ui-material';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MccColorPickerModule} from 'material-community-components/color-picker';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {CustomColorPickerModule} from "@shared/modules/color-picker/custom-color-picker.module";
import {MatLegacyAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatLegacyCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyOptionModule} from "@angular/material/legacy-core";
import {SharedPipesModule} from "@shared/pipes/shared-pipes.module";
import {NgxMatFileInputModule} from "@angular-material-components/file-input";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFormsCoreModule.forRoot(),
        DynamicFormsMaterialUIModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        MatSlideToggleModule,
        MccColorPickerModule,
        MatInputModule,
        CustomColorPickerModule,
        MatLegacyAutocompleteModule,
        MatLegacyCheckboxModule,
        MatLegacyOptionModule,
        SharedPipesModule,
        NgxMatFileInputModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormComponent,
        ModelNameDirective,
        AfterModelNameDirective
    ],
    declarations: [
        FormComponent,
        ModelNameDirective,
        AfterModelNameDirective
    ],
    providers: [
        {
            provide: NG_VALIDATORS,
            useValue: serverValidator,
            multi: true
        },
        {
            provide: DYNAMIC_VALIDATORS,
            useValue: new Map<string, Validator | ValidatorFactory>([
                ["serverValidator", serverValidator]
            ])
        },
        DISABLED_MATCHER_PROVIDER,
        HIDDEN_MATCHER_PROVIDER,
        REQUIRED_MATCHER_PROVIDER
    ],
})
export class FormModule {
}
