import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Injectable()
export class FormService {

    createFormDataWithFile(form: UntypedFormGroup, fileName: string) {
        const formData = new FormData();
        this.appendFile(form, formData, fileName);

        form.removeControl(fileName);

        const createFormData = form.value;

        for (const key in createFormData) {
            if (createFormData.hasOwnProperty(key)) {
                formData.append(`data[${key}]`, createFormData[key]);
            }
        }

        return formData;
    }

    createFormDataWithFilesIfExists(form: UntypedFormGroup, data: any): {} {
        const formFields: { string: any } = form.value;

        const containsFile = Object.values(formFields).some(value => value instanceof File || value instanceof FileList);

        if (!containsFile) {
            return data;
        }

        const formData       = new FormData();
        const createFormData = form.value;

        for (const key in createFormData) {
            if (createFormData.hasOwnProperty(key)) {
                if (createFormData[key] instanceof File) {
                    this.appendFile(form, formData, key);
                    console.log(1, key, createFormData[key])
                } else {
                    console.log(2, key, createFormData[key])
                    formData.append(`data[${key}]`, createFormData[key]);
                    console.log(3, formData.has('data'))
                }
            }
        }

                    console.log(4, formData)

        return formData;
    }

    private appendFile(form: UntypedFormGroup, formData: FormData, fileName: string) {
        const fileInput = form.get(fileName).value;
        if (!fileInput) {
            return;
        }

        formData.append(fileName, fileInput);
    }
}
