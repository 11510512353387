import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DynamicFormControlModel, DynamicFormService} from '@ng-dynamic-forms/core';

import {routerTransition} from '../../../../router.animations';
import {FormBuilder} from '@shared/modules/form/form-builder';
import {ServerValidation} from '@shared/modules/form/validators/server-validation';
import {serverValidator} from '@shared/modules/form/validators/server-validator';
import {CrudService} from '../services/crud.service';
import {take} from 'rxjs';
import {CreateOptions} from '@shared/components/crud/create/types';
import {FormService} from "@shared/services/form.service";

@Component({
    selector:      'app-crud-create',
    template:      '',
    encapsulation: ViewEncapsulation.None,
    animations:    [routerTransition()],
    providers:     [
        DynamicFormService
    ]
})

export class CrudCreateComponent implements OnInit {

    public successMessage                       = '';
    public formModel: DynamicFormControlModel[] = [];
    public formGroup: UntypedFormGroup;

    constructor(
        public _crudService: CrudService,
        public formService: DynamicFormService,
        private appFormService: FormService = null,
    ) {
    }

    ngOnInit() {
        this.initForm();
    }

    initForm(model: any = {}, options: CreateOptions = {}) {
        options.route = options.route || {params: {type: 'create'}};

        this._crudService.getCreateFormConfigurations(options)
            .pipe(take(1))
            .subscribe((data) => {
                this.formModel = (new FormBuilder(data['fields'], model)).build();
                this.formGroup = this.formService.createFormGroup(this.formModel);
            });
    }

    validate(currentControl: DynamicFormControlModel, options: CreateOptions = {route: {}}) {
        options.route      = options.route || {};
        options.route.data = options.route.data || {data: this.formGroup.value};

        if (!('serverValidator' in currentControl['validators']) || this.formGroup.get(currentControl.name).invalid) {
            if (options.callbacks) {
                options.callbacks.success({});
            }

            return;
        }

        this._crudService.validateOnCreate(options)
            .pipe(take(1))
            .subscribe(
                (data) => {
                    if (options.callbacks && options.callbacks.success) {
                        options.callbacks.success(data);
                    }
                },
                (error) => {
                    ServerValidation.validateControl(error.errors, currentControl.name, this.formModel, this.formGroup);

                    if (options.callbacks && options.callbacks.error) {
                        options.callbacks.error();
                    }
                },
            );
    }

    create(options: CreateOptions = {route: {}}) {
        options.route      = options.route || {};
        options.route.data = options.route.data || {data: this.formGroup.value};

        if (this.appFormService) {
            options.route.data = this.appFormService
                .createFormDataWithFilesIfExists(this.formGroup, options.route.data['data']);
        }

        this._crudService.create(options)
            .pipe(take(1))
            .subscribe((data) => {
                    this.successMessage = data.message;

                    if (!data.errors && options.callbacks && options.callbacks.success) {
                        options.callbacks.success(data);
                    }
                },
                (errors) => {
                    const errorsResponse = errors instanceof Object ? errors : JSON.parse(errors._body);

                    ServerValidation.validateControls(errorsResponse.errors, this.formModel, this.formGroup);

                    if (options.callbacks && options.callbacks.error) {
                        options.callbacks.error();
                    }
                }
            );
    }

    isInvalidForm() {
        return this.formGroup.invalid;
    }

}
